<template>
  <div class="home">
    <van-nav-bar title="驾校列表" right-text="报名记录" @click-right="onClickRight" left-text="重新定位" @click-left="reLocation"></van-nav-bar>
    <div style="padding: 12px 16px 32px 16px;">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadSchoolList"
      >
        <van-row v-for="(school, index) in schools" :key="index" align="top" :gutter="12"  style="background: #f2f2f2;padding: 6px;border-radius: 4px;margin-bottom:16px;" @click="toSchoolDetail(school.id)">
          <van-col span="6">
            <van-image v-if="school.imageList.length==0" :radius="4" width="100%" fit="cover" :src="require('../assets/school-cover-default.jpg')"  />
            <van-image  v-else :radius="4" width="100%" fit="cover" :src="`${assetsHost}${school.imageList[0]}`"  />
          </van-col>
          <van-col span="18">
            <div>
              <div><b>{{school.name}}</b></div>
              <div style="padding:8px 0px;font-size: 13px;color: #999;">{{school.address}}</div>
              <div style="color: #999;font-size: 13px;"><van-icon name="phone-o" /> {{school.contactTel}}</div>
              <div style="color: #27b2ce;font-size:12px;padding-top:2px;text-align: right;">
                直线距离{{school.distanceText?school.distanceText:' -- km'}}
                <van-button type="primary" @click.stop="toNav(school)" size="mini" plain style="margin-left:10px;" icon="location-o">导航</van-button>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-list>
    </div>

  <foot></foot>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import foot from "@/views/foot";
wx.error(function(res){
  console.log(res)
});
wx.ready(function(){
  console.log("微信config ready")
})
import  {API_HOST, ASSETS_HOST } from '@/utils/constant'
import {Toast} from "vant";
export default {
  name: 'SchoolList',
  components: { foot },
  data () {
    return {
      loading: false,
      finished: false,
      schools: [],
      lat: -1,
      lng: -1,
      totalPage: 0,
      pi: 0,
      assetsHost: ASSETS_HOST,
      locationing: true,
      authtoken: null,
      user: {},
      type: 'car'
    }
  },
  mounted() {
    this.authtoken = this.$route.query.authtoken
    const type = this.$route.query.type
    if (this.authtoken) {
      localStorage.setItem("authtoken", this.authtoken)
    }
    if (type) {
      this.type = type
    }
    this.reLocation();
    this.loadCurrentUser()
    this.loadJSAPISign()
  },
  methods: {
    toNav (school) {
      if (school.gpslocation.length==0) {
        Toast('无GPS点位')
        return;
      }
      wx.openLocation({
        latitude: parseFloat(school.gpslocation[1]), // 纬度，浮点数，范围为90 ~ -90
        longitude: parseFloat(school.gpslocation[0]), // 经度，浮点数，范围为180 ~ -180。
        name: school.name, // 位置名
        address: school.address, // 地址详情说明
        scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      })
    },
    loadJSAPISign () {
      const url = encodeURI(location.href)
      const data = {
        url : url
      }
      this.$http.post(`${API_HOST}/public/jsSign`, data).then((response) => {
        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
            wx.config({
              debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature,// 必填，签名
              jsApiList: ['openLocation'] // 必填，需要使用的 JS 接口列表
            });
          } else {
            Toast(res.msg)
          }
        } else {
          Toast('网络请求失败')
        }
      })
    },

    reLocation () {
      const loading = Toast({ loadingType: "circular", forbidClick: true, message: '定位获取中...' })
      this.locationing = true
      navigator.geolocation.getCurrentPosition((success) => {
        this.locationing = false
        this.lat = success.coords.longitude
        this.lng = success.coords.latitude
        this.pi = 0;
        this.totalPage = 0;
        this.schools = []
        this.finished = false;
        this.loadSchoolList()

        loading.clear()
      }, () => {
        this.locationing = false
        loading.clear()
        Toast('定位失败...')
        this.loadSchoolList()
      })
    },

    onClickRight() {
      this.$router.push(`/sign-records?type=${this.type}`)
    },
    toSchoolDetail (schoolId) {
      this.$router.push(`/school-detail?id=${schoolId}&type=${this.type}`)
    },

    loadCurrentUser () {
      this.$http.get(`${API_HOST}/h5/userInfo`, {
        headers: {
          authtoken: localStorage.getItem("authtoken")
        }
      }).then((response) => {
            if (response.status === 200) {
              const res = response.data
              if (res.code === 0 && res.ok) {
                this.user = res.data
              } else {
                Toast(res.msg)
              }
            } else {
              Toast('网络请求失败')
            }
      })
    },

    async loadSchoolList() {

      if (this.pi > this.totalPage || this.finished || this.locationing) {
        return
      }
      this.pi = this.pi + 1
      this.loading = true

      const loading = Toast({ loadingType: "circular", forbidClick: true, message: '加载中...' })
      this.$http.get(`${API_HOST}/public/driver-school/list?lat=${this.lat}&lng=${this.lng}&pi=${this.pi}&type=${this.type}`).then((response) => {
        loading.clear();
        this.loading = false

        if (response.status === 200) {
          const res = response.data
          if (res.code === 0 && res.ok) {
              if (res.data.length === 0) {
                this.finished = true
              }
              res.data.forEach(item => {
                this.schools.push(item)
              })
            this.pi = res.page.pageIndex
            this.totalPage = res.page.totalPage
          } else {
            Toast(res.msg)
          }
        } else {
          Toast('网络请求失败')
        }
      })
    }
  }
}
</script>
<style scoped>
.home{
  display:flex;
  display: -webkit-flex;
  height:100%;
  flex-direction:column;
}
</style>
