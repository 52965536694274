<template>
  <div class="footer">
    <van-divider :style="{ borderColor: '#878889'}" />
    <p>技术支持</p>
    <p>四川交子银通科技有限公司
      <a
          href="http://mp.weixin.qq.com/mp/getmasssendmsg?__biz=Mzk0NzMwNDUwNA==#wechat_webview_type=1&wechat_redirect">【交子银通】</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "foot"
}
</script>

<style scoped>
.footer {
  flex: 1;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  text-align: center;
}

.footer p {
  color: grey;
  font-size: 15px;
}

.footer a {
  color: #42b983;
  text-decoration: underline;
}
</style>
